import { Typography, Box, SvgIcon } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _ from "lodash";
import { Org, OrgRegistrationTeamSummary } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { ShadowView } from "../../components/ShadowView";
import CoolerTable, { CoolerTableColumnDef, CoolerTableMethods } from "../../components/CoolerTable";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { BackButton } from "../../components/BackButton";
import { getBifrost } from "../../services/bifrost.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { COLORS, formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { useEffect, useRef, useState } from "react";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { EyeIcon } from "@heroicons/react/24/outline";
import { StyledAsteriskText } from "../../components/StyledAsteriskTest";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { LoadingIndicator } from "../../utils/openLoadingIndicator";
import { PrettyCheckbox } from "../../components/Form";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export default function OrgReportsRegistrationSummaryByTeam() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsRegistrationSummaryByTeamInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsRegistrationSummaryByTeamInner(p: { org: Org }) {
  const [selectedOrgSeasonId, setSelectedOrgSeasonId] = useOrgCurrentSeasonId(p.org.id);

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedLineItems, setSelectedLineItems] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setSelectedLineItems({});
  }, [selectedOrgSeasonId]);

  const [selectedTeamNames, setSelectedTeamNames] = useState<string[]>([]);
  const [selectedOrgTeamTagId, setSelectedOrgTeamTagId] = useState("");
  const orgSeasons = useOrgSeasons({ orgId: p.org.id }) ?? [];

  const orgTeams = useOrgTeams({ orgId: p.org.id }) ?? [];
  const orgTeamTags = useOrgTeamTags({ orgId: p.org.id }) ?? [];

  const { data: reportData, isFetching } = getBifrost().reports__server__getOrgRegistrationSummaryByTeam.useServer(
    {
      orgId: p.org.id,
      orgSeasonId: selectedOrgSeasonId
    },
    { enabled: !!selectedOrgSeasonId, notifyOnMetaDataChanges: true }
  );

  const allLineItemKeys = reportData?.reduce((acc, val) => {
    val.lineItems.forEach(items => {
      Object.keys(items ?? {}).map(lineItemKey => {
        acc[lineItemKey] = true;
      });
    });
    return acc;
  }, {} as Record<string, true>);

  const columns: CoolerTableColumnDef<OrgRegistrationTeamSummary>[] = [
    {
      label: translate.common.Team,
      getValue(item) {
        return item.teamName;
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.teamName, dir);
      }
    },
    {
      label: translate({ defaultMessage: "# Players" }),
      getValue(item) {
        return item.numPlayers;
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.numPlayers, dir);
      }
    },
    ...Object.keys(allLineItemKeys ?? {})
      .filter(a => (Object.keys(selectedLineItems).length ? selectedLineItems[a] : true))
      .map(lineItemKey => {
        return {
          label: lineItemKey,
          labelLegendValue: "*",
          getValue(item: OrgRegistrationTeamSummary) {
            const totalAmount = _.sum(
              item.lineItems.map(lineItems => {
                return lineItems?.[lineItemKey]?.amountCents ?? 0;
              })
            );
            return formatMoneyCentsToDollarCentPrettyString(totalAmount);
          },
          getValueForTotalRow(items) {
            return formatMoneyCentsToDollarCentPrettyString(
              _.sum(
                items.map(item => {
                  const totalAmount = _.sum(
                    item.lineItems.map(lineItems => {
                      return lineItems?.[lineItemKey]?.amountCents ?? 0;
                    })
                  );
                  return totalAmount;
                })
              )
            );
          }
        };
      }),
    {
      label: translate({ defaultMessage: "Coupons" }),
      getValue(item) {
        return (
          <div className="flex flex-row">
            {formatMoneyCentsToDollarCentPrettyString(item.couponAmountCents)}
            {Object.keys(item.couponCodesWithUsages ?? {}).length ? (
              <TouchableOpacity
                onPress={async e => {
                  e.preventDefault();
                  await getFullScreenModal({
                    title: translate.common.Coupons,
                    containerStyle: { maxWidth: 500 },
                    children: <TeamCouponDetails couponCodesWithUsages={item.couponCodesWithUsages} />
                  });
                }}
                style={{ width: 20, height: 20, marginLeft: 4 }}
              >
                <EyeIcon color={COLORS.blue_66} />
              </TouchableOpacity>
            ) : null}
          </div>
        );
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.couponAmountCents, dir);
      },
      getCellCustomClassName(item) {
        return "text-green-500";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.couponAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Total Amount Committed" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.totalDueCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.totalDueCents, dir);
      },
      getCellCustomClassName(item) {
        return "font-bold";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.totalDueCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Credits" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.creditAmountCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.creditAmountCents, dir);
      },
      getCellCustomClassName(item) {
        return "text-green-500";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.creditAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Amount Paid" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.defaultPaidAmountCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.defaultPaidAmountCents, dir);
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.defaultPaidAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Balance" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.remainingDueCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.remainingDueCents, dir);
      },
      getCellCustomClassName(item) {
        return "font-bold";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.remainingDueCents)));
      }
    }
  ];

  const { pathname } = useLocation();

  const coolerTableRef = useRef<CoolerTableMethods>(null);
  return (
    <View style={{ flex: 1 }}>
      <BackButton />
      <View>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate({ defaultMessage: "Registration Summary By Team" })}</h1>
        <div className="inline-block">
          <CoolSelectInput
            label={translate.common.Season}
            containerStyle={{ marginTop: 30 }}
            placeholder={translate({ defaultMessage: "Select Season" })}
            value={selectedOrgSeasonId}
            inputProps={{
              style: { fontSize: 20, height: 60, minWidth: 280, width: "auto" }
            }}
            options={orgSeasons
              .filter(os => !os.archivedAtMS)
              .map(os => {
                return {
                  label: os.name,
                  value: os.id
                };
              })}
            onChange={newVal => {
              setSelectedOrgSeasonId(newVal);
            }}
          />
        </div>
        <ActionButtonDropdown
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="contained"
          actions={[
            {
              key: "export",
              label: () => translate({ defaultMessage: "Export {num} Rows" }, { num: reportData?.length || "" }),
              onClick: async () => {
                coolerTableRef.current?.downloadCurrentDataToCSV("registration-summary-by-team.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </View>
      {selectedOrgSeasonId ? (
        <div className="relative">
          <div className="absolute inset-0">
            <LoadingIndicator isVisible={isFetching} />
          </div>
          <div className="flex flex-1">
            <div className="absolute inset-0 overflow-x">
              <CoolerTable
                methodsRef={coolerTableRef}
                style={{ marginTop: 16 }}
                items={reportData ?? []}
                paginationOptions={{
                  defaultPageSize: 25,
                  pageSizeOptions: [25, 50, 100]
                }}
                getRowHref={item => `${pathname}/${item.teamId}`}
                filterInstructions={
                  <div className="mb-2">
                    <div>
                      <StyledAsteriskText asteriskClassName="font-bold" className="text-xs text-gray-600">
                        {translate({
                          defaultMessage:
                            "*Note:* Amounts below in this report *only* include players who have actually registered and/or setup their payment plans"
                        })}
                      </StyledAsteriskText>
                    </div>
                    <div>
                      {Object.keys(allLineItemKeys ?? {}).length ? (
                        <StyledAsteriskText delimiter="__" asteriskClassName="font-bold" className="text-xs text-gray-600">
                          {translate({
                            defaultMessage:
                              "__Note:__ Columns with asterisks (*) show the amount __committed__ to be paid, not the amount actually paid."
                          })}
                        </StyledAsteriskText>
                      ) : null}
                    </div>
                  </div>
                }
                filters={[
                  {
                    filterComponent: (
                      <CoolTextInput
                        onChange={newVal => {
                          setSearchTerm(newVal ?? "");
                        }}
                        value={searchTerm}
                        placeholder={translate({ defaultMessage: "Filter by Team Name" })}
                      />
                    ),
                    onFilter(items) {
                      return items.filter(item =>
                        item.teamName.toLowerCase().replace(/\s+/g, "").match(searchTerm.toLowerCase().replace(/\s+/g, ""))
                      );
                    }
                  },
                  orgTeamTags.length
                    ? {
                        filterComponent: (
                          <CoolSelectInput
                            options={_.orderBy(
                              orgTeamTags.map(orgTeamTag => {
                                return {
                                  label: orgTeamTag.tag,
                                  value: orgTeamTag.id
                                };
                              }),
                              a => a.label.toLowerCase,
                              "asc"
                            )}
                            value={selectedOrgTeamTagId ?? ""}
                            placeholder={translate.common.TeamTag}
                            allowClear
                            onChange={newVal => {
                              setSelectedOrgTeamTagId(newVal);
                            }}
                          />
                        ),
                        onFilter: items => {
                          return selectedOrgTeamTagId
                            ? items.filter(item => {
                                const team = orgTeams.find(t => t.id === item.teamId);
                                return team?.assignedOrgTagIds?.[selectedOrgTeamTagId];
                              })
                            : items;
                        }
                      }
                    : null,
                  {
                    filterComponent: (
                      <CoolMultiSelectInput
                        options={
                          reportData?.map(a => {
                            return {
                              label: a.teamName,
                              value: a.teamName
                            };
                          }) ?? []
                        }
                        placeholder={translate({ defaultMessage: "Select Teams" })}
                        onChange={newVal => {
                          setSelectedTeamNames(newVal.map(a => a.value));
                        }}
                        value={selectedTeamNames.map(a => {
                          return {
                            label: a,
                            value: a
                          };
                        })}
                      />
                    ),
                    onFilter(items) {
                      return selectedTeamNames.length === 0 ? items : items.filter(a => selectedTeamNames.includes(a.teamName));
                    }
                  },
                  {
                    filterComponent: (
                      <CoolMultiSelectInput
                        onChange={curr => {
                          setSelectedLineItems(
                            _(curr)
                              .map(a => a.value)
                              .keyBy()
                              .mapValues(() => true)
                              .value()
                          );
                        }}
                        options={Object.keys(allLineItemKeys || {}).map(a => ({ label: a, value: a }))}
                        isMulti
                        placeholder={translate({ defaultMessage: "Select Line Items" })}
                        value={Object.keys(selectedLineItems).map(a => ({ label: a, value: a }))}
                      />
                    ),
                    onFilter: a => a
                  }
                ]}
                columnDefs={columns}
                getItemKey={item => item.teamId}
              />
            </div>
          </div>
        </div>
      ) : null}
    </View>
  );
}

function TeamCouponDetails(p: { couponCodesWithUsages: OrgRegistrationTeamSummary["couponCodesWithUsages"] }) {
  const itemsArray = _.map(p.couponCodesWithUsages, (value, key) => ({
    code: key,
    numUses: value
  }));
  return (
    <CoolerTable
      items={itemsArray}
      columnDefs={[
        {
          label: translate.common.Code,
          getValue(item) {
            return item.code;
          }
        },
        {
          label: translate({ defaultMessage: "# Uses" }),
          getValue(item) {
            return item.numUses;
          }
        }
      ]}
      getItemKey={item => item.code}
    />
  );
}
