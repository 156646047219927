import { Box, Button, SvgIcon, Typography } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _, { isArray, pick } from "lodash";
import {
  AccountInfo,
  ORG_PERMISSIONS,
  Org,
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__Registration,
  OrgPayment,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentInvoiceFailed,
  OrgPaymentInvoiceFailedECheck,
  OrgPaymentType,
  OrgRegistrationPackage,
  OrgRegistrationPackageId,
  OrgRegistrationStatus,
  OrgSeason,
  OrgSeasonId,
  OverallInvoiceStatus,
  PlayerBundle,
  PlayerBundleId,
  PlayerBundle__AccountType,
  PrettyPlayerBundle,
  TEAM_ROLES,
  Team,
  Team__StaffTypes
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { useOrg } from "../../hooks/useOrg";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  ObjectKeys,
  OrgMemberDetailsData,
  OrgMemberDetailsDataPlayerBundle,
  OrgMemberDetailsPlayerBundleMetadata,
  OrgMemberDetailsPlayerBundleMetadataSimple,
  OrgPaymentDetailsPast,
  OrgPaymentDetailsScheduled,
  PRETTY_ORG_COUPON_TYPE,
  PRETTY_ORG_COUPON_USE_TYPE,
  PRETTY_ORG_REGISTRATION_STATUS,
  PRETTY_OVERALL_ORG_INVOICE_STATUS,
  PRETTY__ORG_PAYMENT_TYPE,
  PlayerBundleRegistrationData,
  Team__StaffPresets,
  compute,
  filterOrgPaymentInvoices,
  formatMoneyCentsToDollarCentPrettyString,
  getOrgPaymentDetails,
  getOrgRegistrationAnswerStringOrArray,
  getOverallOrgInvoiceAmountDetails,
  getPrettyGenderTitle,
  htmlify,
  isChildOrgInvoice,
  isParentOrgInvoice
} from "@ollie-sports/core";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import CoolerTable from "../../components/CoolerTable";
import { StyledText } from "../../components/StyledText";
import { useAccounts } from "../../hooks/useAccounts";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { ProfileCircle } from "../../components/ProfileCircle";
import { ReactNode, useState } from "react";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import moment from "moment";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { CurrencyDollarIcon, LinkIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { openOrgPaymentInvoiceCreditAddModal } from "./OrgPaymentInvoiceCreditAdd";
import getConfirm from "../../components/modals/getConfirm";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { openOrgCouponsAddEditModal } from "./OrgCouponsAddEdit";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { orgInvoice, orgPaymentPlan, playerBundle } from "@ollie-sports/core/dist/compute";
import { useOrgRegistrationPackages } from "../../hooks/useOrgRegistrationPackages";
import OrgInvoiceRegistration from "./OrgInvoiceRegistration";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { OrgRegistrationAnswersTable } from "./components/OrgRegistrationAnswersTable";
import { config } from "../../config";
import { BackButton } from "../../components/BackButton";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { openOrgPlayerBundleNoteAddModal } from "./OrgPlayerBundleNoteAdd";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { getLabelFromPaymentMethodSnapshot } from "./OrgInvoiceDetails";

export default function OrgMembersDetails() {
  const params: any = useParams();
  const orgId = params.orgId;
  const accountIdOrPlayerBundleId = params.accountIdOrPlayerBundleId as string;
  const type = accountIdOrPlayerBundleId.includes("playerBundle-") ? "playerBundle" : "account";
  const orgSeasons = useOrgSeasons({ orgId });
  const currentOrgSeasons = orgSeasons?.filter(os => compute.orgSeason.isCurrentOrgSeason(os));
  const currentOrgSeasonIds = currentOrgSeasons?.map(a => a.id);
  const {
    data: orgMemberDetailsData,
    isLoading: isLoadingData,
    refetch
  } = getBifrost().org__server__getOrgMemberDetailsData.useServer(
    type === "account"
      ? {
          type: "account",
          accountId: accountIdOrPlayerBundleId,
          orgId,
          currentOrgSeasonIds
        }
      : {
          type: "playerBundle",
          playerBundleId: accountIdOrPlayerBundleId,
          orgId,
          currentOrgSeasonIds
        },
    { notifyOnMetaDataChanges: true }
  );

  const { org, isLoading: isOrgLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoadingData || isOrgLoading ? (
          <CenteredLoader />
        ) : !org || !orgMemberDetailsData ? (
          <Typography>{translate({ defaultMessage: "Failed to load data" })}</Typography>
        ) : (
          <OrgMembersDetailsInner
            org={org}
            orgMemberDetailsData={orgMemberDetailsData}
            accountIdOrPlayerBundleId={accountIdOrPlayerBundleId}
            onRefetch={async () => {
              await refetch();
            }}
          />
        )}
      </View>
    </Box>
  );
}

function OrgMembersDetailsInner(p: {
  org: Org;
  orgMemberDetailsData: OrgMemberDetailsData;
  accountIdOrPlayerBundleId: string;
  onRefetch: () => Promise<void>;
}) {
  const { org, orgMemberDetailsData } = p;
  const allOrgTeams =
    useOrgTeams({
      orgId: org.id
    }) ?? [];
  const history = useHistory();

  const playerBundleTeamIds =
    orgMemberDetailsData.type === "account"
      ? []
      : _.compact(
          Object.values(
            orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.derived.linkedPlayers ?? {}
          ).map(a => {
            if (a.status === "active") {
              return a.teamId;
            }
            return null;
          })
        );

  const teams = allOrgTeams.filter(
    t =>
      !!(orgMemberDetailsData.type === "account" && t.accounts[orgMemberDetailsData.account.id]?.exists) ||
      !!playerBundleTeamIds.includes(t.id)
  );

  const accountInfo: AccountInfo =
    orgMemberDetailsData.type === "account"
      ? orgMemberDetailsData.account
      : orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.derived.accountInfo;

  const actionOptions = _.compact([
    p.orgMemberDetailsData.type === "account"
      ? {
          key: "paymentMethods",
          label: () => translate({ defaultMessage: "Manage Payment Methods" }),
          onClick: async () => {
            history.push({
              pathname: `/app/org/${p.org.id}/members/${p.accountIdOrPlayerBundleId}/payment-info-iframe`
            });
          }
        }
      : null
  ]);

  const nonSelfPlayerBundles =
    orgMemberDetailsData.type === "account"
      ? orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.filter(
          a =>
            a.prettyPlayerBundle.playerBundle.managingAccounts?.[p.accountIdOrPlayerBundleId]?.type !==
            PlayerBundle__AccountType.selfAthlete
        )
      : [];

  const hasOrgViewFinancesPermission = compute.org.hasOrgViewFinanacesPermission({
    accountId: getCurrentUserAccountId(),
    org: p.org
  });

  const hasOrgPlayerNotesPermission = compute.org.hasOrgPlayerNotesPermission({
    accountId: getCurrentUserAccountId(),
    org: p.org
  });

  const bundlesWithMetadataWhereUserIsSelfAthlete: OrgMemberDetailsPlayerBundleMetadata[] =
    p.orgMemberDetailsData.type === "playerBundle"
      ? [p.orgMemberDetailsData.prettyPlayerBundleWithMetadata]
      : p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.filter(
          a =>
            a.prettyPlayerBundle.playerBundle.managingAccounts?.[p.accountIdOrPlayerBundleId]?.type ===
            PlayerBundle__AccountType.selfAthlete
        );

  return (
    <View style={{ flex: 1 }}>
      <BackButton />
      <View style={{ flexDirection: "row", marginTop: 16 }}>
        <ProfileCircle
          accountInfo={
            orgMemberDetailsData.type === "account"
              ? orgMemberDetailsData.account
              : orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.derived.accountInfo
          }
          size={100}
          style={{ marginRight: 30 }}
        />
        <View style={{ flex: 1 }}>
          <StyledText style={{ fontSize: 32, fontWeight: "bold", marginBottom: 8 }}>
            {`${accountInfo.firstName} ${accountInfo.lastName}`}
          </StyledText>
          <RolesRow orgMemberDetailsData={orgMemberDetailsData} orgId={org.id} />
          <StyledText>{accountInfo.email}</StyledText>
          {accountInfo.phoneNumber ? <StyledText>{accountInfo.phoneNumber}</StyledText> : null}
          {orgMemberDetailsData.type === "playerBundle" ? (
            <RegistrationLabel
              status={compute.orgRegistration.getPlayerRegistrationStatus(
                orgMemberDetailsData.prettyPlayerBundleWithMetadata.registrationData?.map(a => a.status) ?? []
              )}
            />
          ) : null}
        </View>

        <View>
          <ActionButtonDropdown disabled={actionOptions.length === 0} style={{ marginLeft: "auto" }} actions={actionOptions}>
            <span style={{ fontWeight: "normal", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
            <SvgIcon style={{ paddingRight: 6 }}>
              <MoreHorizontal />
            </SvgIcon>
          </ActionButtonDropdown>
        </View>
      </View>
      {teams.length ? <TeamsTable teams={teams} orgMemberDetailsData={orgMemberDetailsData} onRefetch={p.onRefetch} /> : null}
      {nonSelfPlayerBundles.length ? (
        <ManagedPlayerBundlesTable
          orgId={org.id}
          managedPrettyPlayerBundlesWithMetadata={nonSelfPlayerBundles}
          triggerRefresh={p.onRefetch}
          title={translate.common.Players}
        />
      ) : null}
      {bundlesWithMetadataWhereUserIsSelfAthlete.length ? (
        <GuardiansTable
          orgId={org.id}
          prettyPlayerBundles={bundlesWithMetadataWhereUserIsSelfAthlete.map(a => a.prettyPlayerBundle)}
        />
      ) : null}
      {hasOrgViewFinancesPermission ? (
        <View>
          <CouponsTable org={org} orgMemberDetailsData={orgMemberDetailsData} onRefetch={p.onRefetch} />
          {orgMemberDetailsData.type === "playerBundle" ? (
            <RegistrationAnswersSection orgMemberDetailsData={orgMemberDetailsData} />
          ) : null}
          <RegistrationsTable orgMemberDetailsData={orgMemberDetailsData} onRefetch={p.onRefetch} orgId={org.id} />
          <InvoiceTable orgMemberDetailsData={orgMemberDetailsData} onRefetch={p.onRefetch} />
          <PaymentsTable orgMemberDetailsData={orgMemberDetailsData} />
        </View>
      ) : null}
      {bundlesWithMetadataWhereUserIsSelfAthlete.length === 1 && hasOrgPlayerNotesPermission ? (
        <NotesTable
          playerBundleId={bundlesWithMetadataWhereUserIsSelfAthlete[0].prettyPlayerBundle.playerBundle.id}
          orgId={p.org.id}
        />
      ) : null}
    </View>
  );
}

function NotesTable(p: { playerBundleId: PlayerBundleId; orgId: OrgId }) {
  const { data: notes } = getBifrost().playerBundleNote__client__getPlayerBundleNotesForOrgSubscription.useClientSubscription({
    orgId: p.orgId,
    playerBundleId: p.playerBundleId
  });
  const accountIds = notes?.map(a => a.appliedByAccountId) ?? [];
  const { accounts } = useAccounts({ accountIds });
  const items = _.orderBy(notes ?? [], a => a.createdAtMS, "desc").map(note => {
    return {
      note,
      account: accounts.find(acc => acc.id === note.appliedByAccountId)
    };
  });

  const lastItemId = _.last(items)?.note.id;
  return (
    <TableSectionWrapper
      title={translate.common.Notes}
      rightButton={
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            openOrgPlayerBundleNoteAddModal({
              orgId: p.orgId,
              playerBundleId: p.playerBundleId,
              onComplete: async () => {}
            });
          }}
        >
          <SvgIcon style={{ paddingRight: 6 }}>
            <PlusCircleIcon />
          </SvgIcon>
          {translate({ defaultMessage: "New Note" })}
        </Button>
      }
    >
      <CoolerTable
        items={items}
        getRowCustomClassName={item => {
          return item.note.id === lastItemId ? "" : "border-gray-100 border-b-2";
        }}
        rowButtons={[
          {
            getLabel(item) {
              return "";
            },
            async onClick(item) {
              const confirm = await getConfirm({
                title: translate.common.Delete,
                subtitle: translate({ defaultMessage: "Are you sure you want to delete this note?" }),
                confirmButtonColor: "red",
                confirmText: translate.common.Delete
              });
              if (confirm) {
                try {
                  await wrapPromiseWithLoader(
                    getBifrost().playerBundleNote__client__deleteNote.fetchClient({ playerBundleNoteId: item.note.id })
                  );
                } catch (e) {
                  openErrorToast(translate.common.SomethingWentWrong);
                }
              }
            },
            type: "inline",
            getIcon: () => <TrashIcon color={COLORS.red_66} />,
            getTheme(item) {
              return "red";
            }
          }
        ]}
        columnDefs={[
          {
            getValue(item) {
              return <div dangerouslySetInnerHTML={{ __html: htmlify(item.note.note) }} />;
            },
            label: translate.common.Note
          },
          {
            getValue(item) {
              return item.account ? `${item.account.firstName} ${item.account.lastName}` : "";
            },
            label: translate({ defaultMessage: "Written By" })
          },
          {
            label: translate.common.Date,
            getValue(item) {
              return dateFormatters.m_d_yy_t_tt_a(moment(item.note.createdAtMS), getCurrentLocale());
            }
          }
        ]}
        getItemKey={item => item.note.id}
        noItemsMessage={translate({ defaultMessage: "No notes yet" })}
      />
    </TableSectionWrapper>
  );
}

function RegistrationsTable(p: { orgMemberDetailsData: OrgMemberDetailsData; onRefetch: () => Promise<void>; orgId: OrgId }) {
  const orgSeasons = useOrgSeasons({ orgId: p.orgId, includeArchived: true });
  const orgRegistrationPackages = useOrgRegistrationPackages({ orgId: p.orgId });
  const playerBundlesWithMetadata = _.compact(
    p.orgMemberDetailsData.type === "playerBundle"
      ? [p.orgMemberDetailsData.prettyPlayerBundleWithMetadata]
      : [...p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata]
  );
  const flattenedPlayerBundlesWithMetadataThatHaveRegistrationData = playerBundlesWithMetadata
    .filter(ppb => !!ppb.registrationData)
    .reduce(
      (acc, a) => {
        a.registrationData?.map(regData => {
          const orgRegistrationPackage = orgRegistrationPackages?.find(
            orp => orp.id === regData.prioritizedRegistrationPackageId
          );
          const orgSeason = orgSeasons?.find(os => os.id === regData.orgSeasonId);
          if (orgSeason) {
            acc.push({
              ...a,
              registrationData: {
                ...regData,
                orgSeason,
                orgRegistrationPackage
              }
            });
          }
          return null;
        });
        return acc;
      },
      [] as (Omit<OrgMemberDetailsPlayerBundleMetadata, "registrationData"> & {
        registrationData: PlayerBundleRegistrationData & {
          orgSeason: OrgSeason;
          orgRegistrationPackage?: OrgRegistrationPackage;
        };
      })[]
    );
  if (!flattenedPlayerBundlesWithMetadataThatHaveRegistrationData.length) {
    return null;
  }

  function getOrgInvoiceAndDetailsOrRegistrationPackage(item: {
    registrationData: PlayerBundleRegistrationData;
    orgInvoices: OrgInvoice[];
    orgPayments: OrgPayment[];
    prettyPlayerBundle: PrettyPlayerBundle;
  }) {
    let orgInvoice: OrgInvoice__Registration | undefined = undefined;
    if (item.registrationData.orgRegistration) {
      orgInvoice = item.orgInvoices.find(
        oi => oi.type === OrgInvoiceTypes.registration && oi.id === item.registrationData.orgRegistration?.orgInvoiceId
      ) as OrgInvoice__Registration;
      if (orgInvoice) {
        const childrenOrgInvoices = _.uniqBy(
          item.orgInvoices.filter(
            oi =>
              (oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
              oi.parentOrgInvoiceId === (orgInvoice as OrgInvoice__Registration).id
          ) as OrgInvoiceChild[],
          a => a.id
        );
        const orgInvoiceDetails = getOverallOrgInvoiceAmountDetails({
          parentOrgInvoice: orgInvoice as OrgInvoice__Registration,
          childrenOrgInvoices,
          orgPayments: _.uniqBy(
            filterOrgPaymentInvoices(item.orgPayments).filter(op =>
              [(orgInvoice as OrgInvoice__Registration).id, ...childrenOrgInvoices.map(oi => oi.id)].includes(op.invoiceId)
            ),
            a => a.id
          )
        });
        const orgRegistrationPackage = orgRegistrationPackages?.find(
          orp => orp.id === item.registrationData.prioritizedRegistrationPackageId
        );
        return { type: "invoice", orgInvoice: orgInvoice as OrgInvoice__Registration, orgInvoiceDetails, orgRegistrationPackage };
      }
    } else if (
      item.registrationData.prioritizedRegistrationPackageId &&
      item.registrationData.prioritizedRegistrationPackageId !== "false"
    ) {
      const orgRegistrationPackage = orgRegistrationPackages?.find(
        orp => orp.id === item.registrationData.prioritizedRegistrationPackageId
      );
      if (orgRegistrationPackage) {
        return {
          type: "package",
          orgRegistrationPackage
        };
      }
    }
    return null;
  }

  return (
    <TableSectionWrapper title={translate.common.Registrations}>
      <CoolerTable
        getRowHref={item => {
          const orgInvoiceAndDetailsOrRegistrationPackage = getOrgInvoiceAndDetailsOrRegistrationPackage(item);
          if (orgInvoiceAndDetailsOrRegistrationPackage?.orgInvoice) {
            return `/app/org/${p.orgId}/invoices/${orgInvoiceAndDetailsOrRegistrationPackage.orgInvoice.id}`;
          } else if (orgInvoiceAndDetailsOrRegistrationPackage?.orgRegistrationPackage) {
            return null;
          }
          return null;
        }}
        columnDefs={[
          p.orgMemberDetailsData.type === "account"
            ? {
                label: translate.common.Player,
                getValue(item) {
                  return (
                    <StyledText>{`${item.prettyPlayerBundle.derived.accountInfo.firstName} ${item.prettyPlayerBundle.derived.accountInfo.lastName}`}</StyledText>
                  );
                },
                sortItems(items, dir) {
                  return _.orderBy(
                    items,
                    item => {
                      return `${item.prettyPlayerBundle.derived.accountInfo.firstName} ${item.prettyPlayerBundle.derived.accountInfo.lastName}`;
                    },
                    dir
                  );
                }
              }
            : null,
          {
            label: translate.common.Season,
            getValue(item) {
              const orgSeason = orgSeasons?.find(os => os.id === item.registrationData.orgSeasonId);
              return orgSeason?.name ?? "";
            }
          },
          {
            label: translate.common.Date,
            getValue(item) {
              const orgSeason = orgSeasons?.find(os => os.id === item.registrationData.orgSeasonId);
              return orgSeason
                ? `${dateFormatters.mm_dd_yyyy(
                    moment(orgSeason.startDateMS).toDate(),
                    getCurrentLocale()
                  )} - ${dateFormatters.mm_dd_yyyy(moment(orgSeason.endDateMS).toDate(), getCurrentLocale())}`
                : "";
            }
          },
          {
            label: translate.common.RegistrationPackage,
            getValue(item) {
              const allRankedPackages = _.compact(
                Object.keys(item.registrationData.allMatchingPackagesWithRankings ?? {}).map(orpId => {
                  const orp = orgRegistrationPackages?.find(orp => orp.id === orpId);
                  if (orp) {
                    return {
                      orgRegistrationPackage: orp,
                      rank: item.registrationData.allMatchingPackagesWithRankings![orpId]
                    };
                  }
                  return null;
                })
              );
              const orgRegistrationPackage = orgRegistrationPackages?.find(
                orp => orp.id === item.registrationData.prioritizedRegistrationPackageId
              );
              // Special case where a player matches multiple packages for one season.
              // If they are registered statuts, everything is good and no need to show the options
              if (item.registrationData.status !== OrgRegistrationStatus.registered && allRankedPackages.length) {
                return (
                  <div className="flex flex-row">
                    <div>{orgRegistrationPackage?.name ?? translate.common.None}</div>
                    <div className="h-4 w-4 ml-2 ">
                      <TouchableOpacity
                        onPress={async e => {
                          e.preventDefault();
                          const modal = openModal({
                            uniquenessId: "manage-assigned-package-for-player-bundle-modal",
                            body: (
                              <ManageExemptPackagesForPlayerBundle
                                allMatchingPackagesWithRankings={allRankedPackages}
                                orgRegistrationPackages={orgRegistrationPackages ?? []}
                                orgSeason={item.registrationData.orgSeason}
                                playerBundle={item.prettyPlayerBundle.playerBundle}
                                assignedOrgRegistrationPackageId={item.registrationData.prioritizedRegistrationPackageId}
                                onRequestDismiss={() => {
                                  modal.close();
                                }}
                                onSave={async () => {
                                  setTimeout(() => {
                                    p.onRefetch();
                                  }, 2000);
                                }}
                              />
                            )
                          });
                        }}
                      >
                        <PencilIcon color={COLORS.blue_66} />
                      </TouchableOpacity>
                    </div>
                  </div>
                );
              }
              if (item.registrationData.prioritizedRegistrationPackageId === "false") {
                return "";
              }
              return orgRegistrationPackage?.name ?? "";
            }
          },
          {
            label: translate.common.Amount,
            getValue(item) {
              const orgInvoiceAndDetailsOrRegistrationPackage = getOrgInvoiceAndDetailsOrRegistrationPackage(item);
              if (orgInvoiceAndDetailsOrRegistrationPackage?.orgInvoiceDetails) {
                return formatMoneyCentsToDollarCentPrettyString(
                  orgInvoiceAndDetailsOrRegistrationPackage.orgInvoiceDetails?.totalAmount
                );
              } else if (orgInvoiceAndDetailsOrRegistrationPackage?.orgRegistrationPackage) {
                return formatMoneyCentsToDollarCentPrettyString(
                  orgInvoiceAndDetailsOrRegistrationPackage.orgRegistrationPackage.amountCents
                );
              }
              return "";
            }
          },
          {
            label: translate.common.Balance,
            getValue(item) {
              const orgInvoiceAndDetailsOrRegistrationPackage = getOrgInvoiceAndDetailsOrRegistrationPackage(item);
              if (orgInvoiceAndDetailsOrRegistrationPackage?.orgInvoiceDetails) {
                return formatMoneyCentsToDollarCentPrettyString(
                  orgInvoiceAndDetailsOrRegistrationPackage.orgInvoiceDetails?.remainingAmount
                );
              } else if (orgInvoiceAndDetailsOrRegistrationPackage?.orgRegistrationPackage) {
                return formatMoneyCentsToDollarCentPrettyString(
                  orgInvoiceAndDetailsOrRegistrationPackage.orgRegistrationPackage.amountCents
                );
              }
              return "";
            },
            getCellCustomClassName(item) {
              let remainingAmount = 0;
              let totalAmount = 0;
              const orgInvoiceAndDetailsOrRegistrationPackage = getOrgInvoiceAndDetailsOrRegistrationPackage(item);
              if (orgInvoiceAndDetailsOrRegistrationPackage?.orgInvoiceDetails) {
                remainingAmount = orgInvoiceAndDetailsOrRegistrationPackage.orgInvoiceDetails?.remainingAmount ?? 0;
                totalAmount = orgInvoiceAndDetailsOrRegistrationPackage.orgInvoiceDetails?.totalAmount ?? 0;
              } else if (orgInvoiceAndDetailsOrRegistrationPackage?.orgRegistrationPackage) {
                remainingAmount = orgInvoiceAndDetailsOrRegistrationPackage.orgRegistrationPackage.amountCents;
                totalAmount = orgInvoiceAndDetailsOrRegistrationPackage.orgRegistrationPackage.amountCents;
              }
              if (remainingAmount === 0) {
                return "text-green-500";
              } else if (remainingAmount === totalAmount) {
                return "text-red-500";
              }
              return "text-yellow-500";
            }
          },
          {
            label: translate.common.Status,
            getValue(item) {
              return PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[item.registrationData.status];
            }
          },
          {
            label: "",
            getValue(item) {
              const orgInvoiceAndDetailsOrRegistrationPackage = getOrgInvoiceAndDetailsOrRegistrationPackage(item);
              if (orgInvoiceAndDetailsOrRegistrationPackage?.orgRegistrationPackage) {
                return (
                  <TouchableOpacity
                    onPress={e => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        `${window.location.origin}/org/${p.orgId}/register/${item.prettyPlayerBundle.playerBundle.id}/${item.registrationData.orgSeasonId}`
                      );
                      openSuccessToast(translate.common.Copied);
                    }}
                  >
                    <LinkIcon color={COLORS.blue} style={{ width: 24, height: 20 }} />
                  </TouchableOpacity>
                );
              }
              return null;
            }
          }
        ]}
        items={flattenedPlayerBundlesWithMetadataThatHaveRegistrationData}
        getItemKey={item => item.prettyPlayerBundle.playerBundle.id + item.registrationData.orgSeasonId}
      />
    </TableSectionWrapper>
  );
}

function RolesRow(p: { orgMemberDetailsData: OrgMemberDetailsData; orgId: OrgId }) {
  const teams = useOrgTeams({ orgId: p.orgId });
  const roles: (TEAM_ROLES.athlete | TEAM_ROLES.guardian | Team__StaffTypes)[] = [];
  if (p.orgMemberDetailsData.type === "playerBundle") {
    roles.push(TEAM_ROLES.athlete);
  } else {
    const accountId = p.orgMemberDetailsData.account.id;
    if (
      p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.find(
        ppb => ppb.prettyPlayerBundle.playerBundle.managingAccounts?.[accountId]?.type === PlayerBundle__AccountType.selfAthlete
      )
    ) {
      roles.push(TEAM_ROLES.athlete);
    }
    if (
      p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.find(
        ppb => ppb.prettyPlayerBundle.playerBundle.managingAccounts?.[accountId]?.type === PlayerBundle__AccountType.guardian
      )
    ) {
      roles.push(TEAM_ROLES.guardian);
    }
    const allTeamAccounts = _.compact(teams?.map(t => t.accounts[accountId]));
    const allTeamStaffTypes = _(allTeamAccounts.map(ta => ta.staffTitle))
      .compact()
      .uniq()
      .value();
    allTeamStaffTypes.forEach(staffType => {
      roles.push(staffType);
    });
  }
  return (
    <div className="flex flex-row items-center mb-2" style={{ fontFamily: "condensed-bold", fontSize: 14 }}>
      {roles.map(role => {
        const prettyRole =
          role === TEAM_ROLES.athlete
            ? translate.common.Player
            : role === TEAM_ROLES.guardian
            ? translate.common.Guardian
            : role === Team__StaffTypes.assistantCoach
            ? translate.common.AssistantCoach
            : role === Team__StaffTypes.headCoach
            ? translate.common.HeadCoach
            : role === Team__StaffTypes.teamAdmin
            ? translate.common.TeamAdmin
            : role === Team__StaffTypes.staffMember
            ? translate.common.StaffMember
            : "";
        return (
          <div key={role} className="py-1 px-2 rounded-sm mr-1 text-white" style={{ backgroundColor: COLORS.green }}>
            {prettyRole.toUpperCase()}
          </div>
        );
      })}
    </div>
  );
}

function CouponsTable(p: { org: Org; orgMemberDetailsData: OrgMemberDetailsData; onRefetch: () => Promise<void> }) {
  return (
    <TableSectionWrapper
      title={translate({ defaultMessage: "Coupons" })}
      rightButton={
        compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        }) ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              openOrgCouponsAddEditModal({
                org: p.org,
                type: "create",
                playerBundleId:
                  p.orgMemberDetailsData.type === "playerBundle"
                    ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.id
                    : "",
                onComplete: p.onRefetch
              });
            }}
          >
            <SvgIcon style={{ paddingRight: 6 }}>
              <PlusCircleIcon />
            </SvgIcon>
            {translate({ defaultMessage: "New Coupon" })}
          </Button>
        ) : undefined
      }
    >
      <CoolerTable
        items={_.compact(
          p.orgMemberDetailsData.type === "playerBundle"
            ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgCoupons.map(a => {
                if (p.orgMemberDetailsData.type !== "playerBundle") {
                  return null;
                }
                return {
                  orgCoupon: a,
                  playerBundleId: p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.id
                };
              })
            : _.flatten(
                p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a =>
                  a.orgCoupons.map(b => {
                    return {
                      orgCoupon: b,
                      playerBundleId: a.prettyPlayerBundle.playerBundle.id
                    };
                  })
                )
              )
        )}
        columnDefs={[
          {
            label: translate.common.Code,
            getValue(item) {
              return item.orgCoupon.code;
            }
          },
          p.orgMemberDetailsData.type === "account"
            ? {
                label: translate.common.Players,
                getValue(item) {
                  if (!item.orgCoupon.playerBundleIds) {
                    return null; // Shouldn't happen
                  }
                  const relevantBundles =
                    p.orgMemberDetailsData.type === "account"
                      ? p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.filter(
                          a => a.prettyPlayerBundle.playerBundle.id === item.playerBundleId
                        )
                      : [p.orgMemberDetailsData.prettyPlayerBundleWithMetadata];

                  if (!relevantBundles.length) {
                    return null;
                  }
                  return relevantBundles
                    .map(
                      a =>
                        `${a.prettyPlayerBundle.derived.accountInfo.firstName} ${a.prettyPlayerBundle.derived.accountInfo.lastName}`
                    )
                    .join(", ");
                }
              }
            : null,
          {
            getValue(item) {
              return PRETTY_ORG_COUPON_TYPE(getCurrentLocale())[item.orgCoupon.type];
            },
            label: translate.common.Type
          },
          {
            getValue(item) {
              return PRETTY_ORG_COUPON_USE_TYPE(getCurrentLocale())[item.orgCoupon.useType];
            },
            label: translate.common.UseType
          },
          {
            getValue(item) {
              if (!item.orgCoupon.playerBundleIds) {
                return null; // Shouldn't happen
              }
              const relevantBundles =
                p.orgMemberDetailsData.type === "account"
                  ? p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.filter(
                      a => a.prettyPlayerBundle.playerBundle.id === item.playerBundleId
                    )
                  : [p.orgMemberDetailsData.prettyPlayerBundleWithMetadata];

              return relevantBundles
                .map(a => item.orgCoupon.numberTimesUsedByPlayerBundleId?.[a.prettyPlayerBundle.playerBundle.id] || 0)
                .reduce((a, b) => a + b, 0);
            },
            label: translate({
              defaultMessage: "Times Used",
              description: "As in a the number of times a coupon has been used"
            })
          },
          {
            getValue(item) {
              return dateFormatters.mm_dd_yyyy(moment(item.orgCoupon.expirationDateMS).toDate(), getCurrentLocale());
            },
            label: translate({ defaultMessage: "Expiration" })
          }
        ]}
        noItemsMessage={translate({ defaultMessage: "No coupons assigned to this user..." })}
        getItemKey={item => {
          return item.orgCoupon.id + item.playerBundleId;
        }}
      />
    </TableSectionWrapper>
  );
}

function PaymentsTable(p: { orgMemberDetailsData: OrgMemberDetailsData }) {
  const [selectedPaymentType, setSelectedPaymentType] = useState<"succeeded" | "failed" | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<"registration" | "invoice" | undefined>();

  const allPrettyPlayerBundles =
    p.orgMemberDetailsData.type === "playerBundle"
      ? [p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle]
      : p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a => a.prettyPlayerBundle);

  const allOrgInvoices = _.uniqBy(
    p.orgMemberDetailsData.type === "playerBundle"
      ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgInvoices
      : _.flatten(p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a => a.orgInvoices)),
    a => a.id
  );
  const allParentOrgInvoices = allOrgInvoices.filter(oi => isParentOrgInvoice(oi)) as OrgInvoiceParent[];

  const relevantOrgPayments = _(
    p.orgMemberDetailsData.type === "playerBundle"
      ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgPayments
      : _.flatten(p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a => a.orgPayments))
  )
    .uniqBy(a => a.id)
    .filter((a): a is OrgPaymentInvoiceDefault | OrgPaymentInvoiceFailed | OrgPaymentInvoiceFailedECheck =>
      [OrgPaymentType.invoiceDefault, OrgPaymentType.invoiceFailedECheckPayment, OrgPaymentType.invoiceFailedPayment].includes(
        a.type
      )
    )
    .map(a => {
      const orgInvoice = allOrgInvoices.find(oi => oi.id === a.invoiceId);
      if (!orgInvoice) {
        return;
      }

      const prettyPlayerBundle = allPrettyPlayerBundles.find(ppb => ppb.playerBundle.id === orgInvoice.playerBundleId);
      if (!prettyPlayerBundle) {
        return null;
      }

      const parentOrgInvoice = (
        isParentOrgInvoice(orgInvoice)
          ? orgInvoice
          : isChildOrgInvoice(orgInvoice)
          ? allParentOrgInvoices.find(
              oi =>
                (orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                  orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
                orgInvoice.parentOrgInvoiceId === oi.id
            )
          : undefined
      ) as OrgInvoiceParent | undefined;
      if (!parentOrgInvoice) {
        return null;
      }

      return {
        payment: a,
        type: a.type === OrgPaymentType.invoiceDefault ? ("succeeded" as const) : ("failed" as const),
        category:
          orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment ||
          orgInvoice.type === OrgInvoiceTypes.registration
            ? ("registration" as const)
            : ("invoice" as const),
        details: getOrgPaymentDetails({
          orgInvoice,
          parentOrgInvoice,
          orgPaymentInvoiceCredits: [],
          orgPayment: a
        }) as OrgPaymentDetailsPast,
        prettyPlayerBundle,
        associatedInvoice: orgInvoice
      };
    })
    .filter(a => !!a?.details)
    .compact()
    .value();

  if (!relevantOrgPayments.length) {
    return null;
  }

  return (
    <TableSectionWrapper title={translate({ defaultMessage: "Payment History" })}>
      <CoolerTable
        items={relevantOrgPayments}
        paginationOptions={{
          defaultPageSize: 10,
          pageSizeOptions: [10, 25, 50]
        }}
        style={{ flex: 1 }}
        defaultSortSettings={{
          label: translate.common.Date,
          dir: "desc"
        }}
        filters={[
          {
            filterComponent: (
              <CoolSelectInput
                allowClear
                placeholder={translate.common.Type}
                value={selectedPaymentType}
                onChange={newVal => {
                  setSelectedPaymentType(newVal as any);
                }}
                options={[
                  {
                    label: translate({ defaultMessage: "Succeeded" }),
                    value: "succeeded"
                  },
                  { label: translate.common.Failed, value: "failed" }
                ]}
              />
            ),
            onFilter(items) {
              if (!selectedPaymentType) {
                return items;
              }
              if (selectedPaymentType === "succeeded") {
                return items.filter(item => item.payment.type === OrgPaymentType.invoiceDefault);
              } else {
                return items.filter(
                  item =>
                    item.payment.type === OrgPaymentType.invoiceFailedECheckPayment ||
                    item.payment.type === OrgPaymentType.invoiceFailedPayment
                );
              }
            }
          },
          {
            filterComponent: (
              <CoolSelectInput
                allowClear
                placeholder={translate({ defaultMessage: "Category" })}
                value={selectedCategory}
                onChange={newVal => {
                  setSelectedCategory(newVal as "registration" | "invoice" | undefined);
                }}
                options={[
                  {
                    label: translate.common.Invoice,
                    value: "invoice"
                  },
                  {
                    label: translate.common.Registration,
                    value: "registration"
                  }
                ]}
              />
            ),
            onFilter(items) {
              if (!selectedCategory) {
                return items;
              }
              if (selectedCategory === "invoice") {
                return items.filter(item => item.category === "invoice");
              } else {
                return items.filter(item => item.category === "registration");
              }
            }
          }
        ]}
        columnDefs={[
          {
            label: translate.common.ID,
            getValue: item => item.payment.id
          },
          p.orgMemberDetailsData.type === "account"
            ? {
                label: translate.common.Player,
                getValue(item) {
                  return (
                    <StyledText>{`${item.prettyPlayerBundle.derived.accountInfo.firstName} ${item.prettyPlayerBundle.derived.accountInfo.lastName}`}</StyledText>
                  );
                },

                sortItems(items, dir) {
                  return _.orderBy(
                    items,
                    item =>
                      `${item.prettyPlayerBundle.derived.accountInfo.firstName} ${item.prettyPlayerBundle.derived.accountInfo.lastName}`,
                    dir
                  );
                }
              }
            : null,
          {
            label: translate.common.Type,
            getValue: item => (item.type === "succeeded" ? translate({ defaultMessage: "Succeeded" }) : translate.common.Failed),
            sortItems: (items, dir) => _.orderBy(items, item => item.type, dir)
          },
          {
            label: translate({ defaultMessage: "Category" }),
            getValue(item) {
              return item.category === "invoice" ? translate.common.Invoice : translate.common.Registration;
            },
            sortItems(items, dir) {
              return _.orderBy(
                items,
                item => (item.category === "invoice" ? translate.common.Invoice : translate.common.Registration),
                dir
              );
            }
          },
          {
            label: translate.common.Amount,
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(item.details.baseAmountCents);
            },
            sortItems(items, dir) {
              return _.orderBy(items, item => item.details.baseAmountCents, dir);
            },
            getCellCustomClassName(item) {
              if (item.type === "failed") {
                return "text-red-500";
              }
              return "";
            }
          },
          {
            label: translate.common.Memo,
            getValue(item) {
              return item.details.memo;
            },
            sortItems(items, dir) {
              return _.orderBy(items, item => item.details.memo, dir);
            }
          },
          {
            label: translate({ defaultMessage: "Date" }),
            getValue(item) {
              return dateFormatters.mm_dd_yyyy(item.details.paymentDateMS, getCurrentLocale());
            },
            sortItems(items, dir) {
              return _.orderBy(items, item => item.details.paymentDateMS, dir);
            }
          },
          {
            label: translate({ defaultMessage: "Payor" }),
            getValue(item) {
              return item.payment.paymentMethodSnapshot
                ? getLabelFromPaymentMethodSnapshot(item.payment.paymentMethodSnapshot)
                : "";
            }
          }
        ]}
        getItemKey={item => {
          return item.payment.id;
        }}
      />
    </TableSectionWrapper>
  );
}

function InvoiceTable(p: { orgMemberDetailsData: OrgMemberDetailsData; onRefetch: () => Promise<void> }) {
  const allOrgInvoices = _.uniqBy(
    p.orgMemberDetailsData.type === "playerBundle"
      ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgInvoices
      : _.flatten(p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a => a.orgInvoices)),
    a => a.id
  );
  const allManualParentOrgInvoices = allOrgInvoices.filter(oi => oi.type === OrgInvoiceTypes.manual) as OrgInvoiceParent[];
  const allOrgPayments = _.uniqBy(
    p.orgMemberDetailsData.type === "playerBundle"
      ? p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgPayments
      : _.flatten(p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.map(a => a.orgPayments)),
    a => a.id
  );

  if (!allManualParentOrgInvoices.length) {
    return null;
  }

  return (
    <TableSectionWrapper title={translate.common.Invoices}>
      <CoolerTable
        paginationOptions={{
          defaultPageSize: 10,
          pageSizeOptions: [10, 25, 50]
        }}
        style={{ flex: 1 }}
        defaultSortSettings={{
          label: translate.common.Date,
          dir: "desc"
        }}
        rowButtons={[
          {
            getLabel: () => translate({ defaultMessage: "Issue Credit" }),
            isVisible: item => item.details.remainingAmount > 0,
            type: "dropdown",
            onClick: async item => {
              const scheduledChildrenOrgInvoices = allOrgInvoices.filter(
                oi =>
                  (oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment ||
                    oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment) &&
                  oi.parentOrgInvoiceId === item.orgInvoice.id &&
                  !oi.thisInvoicePaidInFullDateMS
              ) as OrgInvoiceChild[];
              const allOrgInvoiceIds = [item.orgInvoice.id, ...scheduledChildrenOrgInvoices.map(oi => oi.id)];
              const orgPayments = filterOrgPaymentInvoices(allOrgPayments).filter(op => allOrgInvoiceIds.includes(op.invoiceId));
              await openOrgPaymentInvoiceCreditAddModal({
                orgInvoiceParent: item.orgInvoice,
                maxAmountCents: item.details.remainingAmount,
                scheduledChildrenOrgInvoices,
                orgPayments,
                onComplete: p.onRefetch
              });
            }
          }
        ]}
        getRowHref={item => {
          return `/app/org/${item.orgInvoice.orgId}/invoices/${item.orgInvoice.id}`;
        }}
        items={allManualParentOrgInvoices.map(parentOrgInvoice => {
          const childrenOrgInvoices = allOrgInvoices.filter(
            oi =>
              (oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
              oi.parentOrgInvoiceId === parentOrgInvoice.id
          ) as OrgInvoiceChild[];
          return {
            orgInvoice: parentOrgInvoice,
            details: getOverallOrgInvoiceAmountDetails({
              parentOrgInvoice: parentOrgInvoice,
              childrenOrgInvoices,
              orgPayments: filterOrgPaymentInvoices(allOrgPayments).filter(op =>
                [parentOrgInvoice.id, ...childrenOrgInvoices.map(coi => coi.id)].includes(op.invoiceId)
              )
            })
          };
        })}
        columnDefs={[
          {
            label: translate.common.ID,
            getValue(item) {
              return item.orgInvoice.id;
            }
          },
          p.orgMemberDetailsData.type === "account"
            ? {
                label: translate.common.Player,
                getValue(item) {
                  const prettyPlayerBundle =
                    p.orgMemberDetailsData.type === "account"
                      ? p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.find(
                          a => a.prettyPlayerBundle.playerBundle.id === item.orgInvoice.playerBundleId
                        )?.prettyPlayerBundle
                      : undefined;
                  if (prettyPlayerBundle) {
                    return (
                      <StyledText>{`${prettyPlayerBundle.derived.accountInfo.firstName} ${prettyPlayerBundle.derived.accountInfo.lastName}`}</StyledText>
                    );
                  } else {
                    return "";
                  }
                },
                sortItems(items, dir) {
                  return _.orderBy(
                    items,
                    item => {
                      const prettyPlayerBundle =
                        p.orgMemberDetailsData.type === "account"
                          ? p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata.find(
                              a => a.prettyPlayerBundle.playerBundle.id === item.orgInvoice.playerBundleId
                            )?.prettyPlayerBundle
                          : undefined;
                      return prettyPlayerBundle
                        ? `${prettyPlayerBundle.derived.accountInfo.firstName} ${prettyPlayerBundle.derived.accountInfo.lastName}`
                        : "";
                    },
                    dir
                  );
                }
              }
            : null,
          {
            label: translate.common.Amount,
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(item.details.totalAmount);
            },
            sortItems(items, dir) {
              return _.orderBy(
                items,
                item => {
                  return item.details.totalAmount;
                },
                dir
              );
            }
          },
          {
            label: translate.common.Balance,
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(item.details.remainingAmount);
            },
            getCellCustomClassName(item) {
              if (item.details.remainingAmount === 0) {
                return "text-green-500";
              } else if (item.details.remainingAmount === item.details.totalAmount) {
                return "text-red-500";
              }
              return "text-yellow-500";
            },
            sortItems(items, dir) {
              return _.orderBy(
                items,
                item => {
                  return item.details.remainingAmount;
                },
                dir
              );
            }
          },
          {
            label: translate.common.Memo,
            getValue(item) {
              return item.orgInvoice.memo;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.orgInvoice.memo, dir);
            }
          },
          {
            label: translate({ defaultMessage: "Date Issued" }),
            getValue(item) {
              return dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.createdAtMS).toDate(), getCurrentLocale());
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.orgInvoice.createdAtMS, dir);
            }
          },
          {
            label: translate.common.Status,
            getValue(item) {
              return PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[item.details.status];
            },
            sortItems(items, dir) {
              return _.orderBy(items, item => item.details.status, dir);
            }
          }
        ]}
        getItemKey={item => {
          return item.orgInvoice.id;
        }}
      />
    </TableSectionWrapper>
  );
}

function GuardiansTable(p: { prettyPlayerBundles: PrettyPlayerBundle[]; orgId: OrgId }) {
  const guardianAccountIds = Object.keys(
    p.prettyPlayerBundles.reduce((acc, ppb) => {
      Object.keys(ppb.playerBundle.managingAccounts ?? {}).forEach(aId => {
        if (ppb.playerBundle.managingAccounts?.[aId]?.type === PlayerBundle__AccountType.guardian) {
          acc[aId] = true;
        }
      });
      return acc;
    }, {} as Record<string, true>)
  );
  const urlParams = useParams<{ teamId?: string }>();
  const location = useLocation();
  const { accounts: guardianAccounts } = useAccounts({ accountIds: guardianAccountIds });

  return (
    <TableSectionWrapper title={translate.common.Guardians}>
      {
        <CoolerTable
          getRowHref={item => {
            return location.pathname.includes("registrations")
              ? `/app/org/${p.orgId}/registrations/members/${item.id}`
              : urlParams.teamId
              ? `/app/org/${p.orgId}/teams/${urlParams.teamId}/guardian/members/${item.id}`
              : `/app/org/${p.orgId}/members/${item.id}`;
          }}
          noItemsMessage={translate({ defaultMessage: "No Guardians" })}
          columnDefs={[
            {
              label: "",
              getValue(item) {
                return <ProfileCircle accountInfo={item} size={30} />;
              },
              headerCustomClassName: "w-14",
              getCellCustomClassName: () => "w-14"
            },
            {
              label: translate.common.Name,
              getValue(item) {
                return `${item.firstName} ${item.lastName}`;
              }
            }
          ]}
          style={{ flex: 1 }}
          getItemKey={item => item.id}
          items={_.orderBy(guardianAccounts, a => `${a.firstName} ${a.lastName}`, "asc")}
        />
      }
    </TableSectionWrapper>
  );
}

function RegistrationLabel(p: { status?: OrgRegistrationStatus }) {
  return (
    <View style={{ flexDirection: "row", marginTop: 12 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor:
            p.status === OrgRegistrationStatus.registered || p.status === OrgRegistrationStatus["no-registration-needed"]
              ? COLORS.green
              : p.status === OrgRegistrationStatus.bad
              ? COLORS.red
              : COLORS.yellow,
          paddingHorizontal: 8,
          paddingVertical: 4,
          borderRadius: 4
        }}
      >
        <StyledText style={{ color: COLORS.white, fontFamily: "condensed-bold", fontSize: 14 }}>
          {PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[p.status ?? OrgRegistrationStatus.unregistered].toUpperCase()}
        </StyledText>
      </View>
    </View>
  );
}

function TeamsTable(p: { teams: Team[]; orgMemberDetailsData: OrgMemberDetailsData; onRefetch: () => Promise<void> }) {
  return (
    <TableSectionWrapper title={translate.common.Teams}>
      <CoolerTable
        getRowHref={item => {
          return `/app/org/${item.orgId}/teams/${item.id}`;
        }}
        columnDefs={[
          {
            label: translate.common.Name,
            getValue(item) {
              return item.name;
            }
          },
          {
            label: translate.common.Gender,
            getValue(item) {
              return getPrettyGenderTitle(item.gender, getCurrentLocale());
            }
          },
          {
            label: translate.common.BirthYear,
            getValue(item) {
              return item.birthYear;
            }
          },
          {
            label: translate.common.Roles,
            getValue(item) {
              if (p.orgMemberDetailsData.type === "playerBundle") {
                return <StyledText>{translate.common.Player}</StyledText>;
              }
              const roleArray = compute.team.getTeamRoleAndStaffComboArrayForAccountId({
                accountId: p.orgMemberDetailsData.account.id,
                team: item
              });
              return (
                <View>
                  {roleArray.map(role => {
                    return (
                      <StyledText key={role}>
                        {role in Team__StaffTypes
                          ? Team__StaffPresets(getCurrentLocale())[role as Team__StaffTypes].staffTitle
                          : role === TEAM_ROLES.athlete
                          ? translate.common.Player
                          : role === TEAM_ROLES.guardian
                          ? translate.common.Guardian
                          : ""}
                      </StyledText>
                    );
                  })}
                </View>
              );
            }
          },
          {
            label: translate.common.HeadCoach,
            getValue(item) {
              return <HeadCoachText team={item} />;
            }
          }
        ]}
        rowButtons={[
          {
            getLabel: () => translate({ defaultMessage: "Remove From Team" }),
            type: "inline",
            getIcon: () => <TrashIcon color={COLORS.red_66} />,
            isVisible(item) {
              if (p.orgMemberDetailsData.type === "playerBundle") {
                return true;
              }
              const selfAthletePlayerBundles = p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata
                .filter(
                  a =>
                    p.orgMemberDetailsData.type === "account" &&
                    a.prettyPlayerBundle.playerBundle.managingAccounts?.[p.orgMemberDetailsData.account.id]
                )
                ?.map(a => a.prettyPlayerBundle);

              const selfAthletePlayerBundleOnTeam = selfAthletePlayerBundles.find(
                ppb =>
                  !!Object.keys(ppb.playerBundle.derived.linkedPlayers).find(
                    playerId =>
                      ppb.playerBundle.derived.linkedPlayers[playerId].status === "active" &&
                      ppb.playerBundle.derived.linkedPlayers[playerId].teamId === item.id
                  )
              );
              if (selfAthletePlayerBundleOnTeam) {
                return true;
              }
              return false;
            },
            onClick: async item => {
              let playerId: string | undefined = undefined;
              let playerName: string | undefined = undefined;
              if (p.orgMemberDetailsData.type === "playerBundle") {
                playerId = Object.keys(
                  p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.derived.linkedPlayers
                ).find(
                  playerId =>
                    p.orgMemberDetailsData.type === "playerBundle" &&
                    p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.derived.linkedPlayers[
                      playerId
                    ].teamId === item.id &&
                    p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.playerBundle.derived.linkedPlayers[
                      playerId
                    ].status === "active"
                );
                playerName =
                  p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.prettyPlayerBundle.derived.accountInfo.firstName;
              } else {
                const selfAthletePlayerBundles = p.orgMemberDetailsData.managedPrettyPlayerBundlesWithMetadata
                  .filter(
                    a =>
                      p.orgMemberDetailsData.type === "account" &&
                      a.prettyPlayerBundle.playerBundle.managingAccounts?.[p.orgMemberDetailsData.account.id]
                  )
                  ?.map(a => a.prettyPlayerBundle);
                const selfAthletePlayerBundleOnTeam = selfAthletePlayerBundles.find(
                  ppb =>
                    !!Object.keys(ppb.playerBundle.derived.linkedPlayers).find(
                      playerId =>
                        ppb.playerBundle.derived.linkedPlayers[playerId].status === "active" &&
                        ppb.playerBundle.derived.linkedPlayers[playerId].teamId === item.id
                    )
                );
                if (selfAthletePlayerBundleOnTeam) {
                  playerId = Object.keys(selfAthletePlayerBundleOnTeam.playerBundle.derived.linkedPlayers).find(
                    pId =>
                      selfAthletePlayerBundleOnTeam.playerBundle.derived.linkedPlayers[pId].status === "active" &&
                      selfAthletePlayerBundleOnTeam.playerBundle.derived.linkedPlayers[pId].teamId === item.id
                  );
                  playerName = selfAthletePlayerBundleOnTeam.derived.accountInfo.firstName;
                }
              }
              if (playerId && playerName) {
                const confirm = await getConfirm({
                  title: translate(
                    { defaultMessage: `Remove {playerName} from {teamName}?` },
                    {
                      playerName,
                      teamName: item.name
                    }
                  ),
                  subtitle: translate({
                    defaultMessage:
                      "Removing the player from the roster will also remove any attached parent/guardian accounts from the team unless those parents/guardians are staff members or have another child on the team."
                  }),
                  cancelText: translate.common.Cancel,
                  confirmText: translate.common.Remove,
                  confirmButtonColor: "red"
                });
                if (confirm) {
                  try {
                    await getBifrost().player__server__deleteFromTeam.fetchServer({
                      data: { playerId }
                    });
                    p.onRefetch();
                  } catch (e) {
                    openErrorToast(translate.common.SomethingWentWrong);
                  }
                }
              }
            }
          }
        ]}
        style={{ flex: 1 }}
        getItemKey={item => item.id}
        items={_.orderBy(p.teams, t => t.name, "asc")}
      />
    </TableSectionWrapper>
  );
}

function ManagedPlayerBundlesTable(p: {
  managedPrettyPlayerBundlesWithMetadata: OrgMemberDetailsPlayerBundleMetadataSimple[];
  triggerRefresh: () => Promise<void>;
  orgId: OrgId;
  title: string;
}) {
  const urlParams = useParams<{ teamId: string }>();
  const location = useLocation();
  const orgTeams =
    useOrgTeams({
      orgId: p.orgId
    }) ?? [];

  const getPBNormName = (a: { prettyPlayerBundle: PrettyPlayerBundle }) => {
    const { firstName, lastName } = a.prettyPlayerBundle.derived.accountInfo;
    return _.startCase(firstName.trim()) + " " + _.startCase(lastName).trim();
  };

  const mergeCandidatesByNormName = _(p.managedPrettyPlayerBundlesWithMetadata)
    .groupBy(getPBNormName)
    .pickBy(a => a.length > 1)
    .value();

  return (
    <TableSectionWrapper title={p.title}>
      <CoolerTable
        getRowHref={item => {
          const selfAccountId = Object.keys(item.prettyPlayerBundle.playerBundle.managingAccounts ?? {}).find(
            aId => item.prettyPlayerBundle.playerBundle.managingAccounts?.[aId]?.type === PlayerBundle__AccountType.selfAthlete
          );
          const accountIdOrPlayerBundleId = selfAccountId ?? item.prettyPlayerBundle.playerBundle.id;
          return location.pathname.includes("registrations")
            ? `/app/org/${p.orgId}/registrations/members/${accountIdOrPlayerBundleId}`
            : urlParams.teamId
            ? `/app/org/${p.orgId}/teams/${urlParams.teamId}/players/members/${accountIdOrPlayerBundleId}`
            : `/app/org/${p.orgId}/members/${accountIdOrPlayerBundleId}`;
        }}
        columnDefs={[
          {
            label: "",
            getValue(item) {
              return <ProfileCircle accountInfo={item.prettyPlayerBundle.derived.accountInfo} size={30} />;
            },
            headerCustomClassName: "w-14",
            getCellCustomClassName: () => "w-14"
          },
          {
            label: translate.common.Name,
            getValue(item) {
              return `${item.prettyPlayerBundle.derived.accountInfo.firstName} ${item.prettyPlayerBundle.derived.accountInfo.lastName}`;
            }
          },
          {
            label: translate.common.Teams,
            getValue(item) {
              const activeTeamIds = Object.values(item.prettyPlayerBundle.playerBundle.derived.linkedPlayers)
                .filter(a => a.status === "active")
                .map(a => a.teamId);
              const teams = orgTeams.filter(t => activeTeamIds.includes(t.id));
              return (
                <View>
                  {teams.map(t => {
                    return <StyledText key={t.id}>{t.name}</StyledText>;
                  })}
                </View>
              );
            }
          },
          {
            label: translate({ defaultMessage: "Registration Status" }),
            getValue(item) {
              return (
                <StyledText>
                  {item.registrationData
                    ? PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[
                        compute.orgRegistration.getPlayerRegistrationStatus(item.registrationData?.map(a => a.status))
                      ]
                    : ""}
                </StyledText>
              );
            }
          }
        ]}
        rowButtons={[
          {
            type: "dropdown",
            isVisible: item => {
              return (
                item.prettyPlayerBundle.derived.accountInfoSource !== "account" &&
                !!mergeCandidatesByNormName[getPBNormName(item)]
              );
            },
            getLabel: () => translate({ defaultMessage: "Delete via Merge" }),
            onClick: async item => {
              const normPlayerName = getPBNormName(item);
              const yes = await getConfirm({
                confirmButtonColor: "red",
                title: translate({ defaultMessage: "Delete via Merge?" }),
                subtitle: translate(
                  {
                    defaultMessage:
                      "Are you sure you wish to delete this {playerName} by merging it with the other {playerName} associated with this account? It does appear likely that one of these players were created in error. All registrations, invoices, teams, and stats associated with this player will be transferred."
                  },
                  { playerName: normPlayerName }
                )
              });

              if (yes) {
                wrapPromiseWithLoader(async () => {
                  const mergePBId = mergeCandidatesByNormName[normPlayerName]!.filter(
                    a => a.prettyPlayerBundle.playerBundle.id !== item.prettyPlayerBundle.playerBundle.id
                  )[0].prettyPlayerBundle.playerBundle.id;

                  const resp = await getBifrost().playerBundle__server__mergePlayerBundles.fetchServer({
                    selfAccountId: getCurrentUserAccountId(),
                    locale: getCurrentLocale(),
                    playerBundleId01: mergePBId,
                    playerBundleId02: item.prettyPlayerBundle.playerBundle.id
                  });

                  if (resp.data.status === "failed") {
                    window.alert(resp.data.reason);
                  } else {
                    await new Promise(res => setTimeout(res, 5000));
                    await p.triggerRefresh();
                  }
                }).catch(e => {
                  console.error(e);
                  openErrorToast(
                    translate({ defaultMessage: "Unable to merge players! Please try again or contact support@olliesports.com" })
                  );
                });
              }
            }
          }
        ]}
        style={{ flex: 1 }}
        getItemKey={item => item.prettyPlayerBundle.playerBundle.id}
        items={_.orderBy(
          p.managedPrettyPlayerBundlesWithMetadata,
          a => `${a.prettyPlayerBundle.derived.accountInfo.firstName} ${a.prettyPlayerBundle.derived.accountInfo.lastName}`,
          "asc"
        )}
      />
    </TableSectionWrapper>
  );
}

function HeadCoachText(p: { team: Team }) {
  const accountIds = ObjectKeys(p.team.accounts).filter(
    accountId => p.team.accounts[accountId]?.staffTitle === Team__StaffTypes.headCoach
  );

  const { accounts } = useAccounts({ accountIds });

  return (
    <View>
      {accounts.map(acc => {
        return <StyledText key={acc.id}>{`${acc.firstName} ${acc.lastName}`}</StyledText>;
      })}
    </View>
  );
}

function RegistrationAnswersSection(p: { orgMemberDetailsData: OrgMemberDetailsDataPlayerBundle }) {
  if (!p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgRegistrationAnswers?.length) {
    return null;
  }

  return (
    <OrgRegistrationAnswersTable
      orgRegistrationAnswers={p.orgMemberDetailsData.prettyPlayerBundleWithMetadata.orgRegistrationAnswers}
    />
  );
}

function ManageExemptPackagesForPlayerBundle(p: {
  playerBundle: PlayerBundle;
  allMatchingPackagesWithRankings: {
    orgRegistrationPackage: OrgRegistrationPackage;
    rank: number;
  }[];
  orgSeason: OrgSeason;
  assignedOrgRegistrationPackageId?: OrgRegistrationPackageId;
  orgRegistrationPackages: OrgRegistrationPackage[];
  onRequestDismiss: () => void;
  onSave: () => Promise<void>;
}) {
  const [exemptPackageIds, setExemptPackageIds] = useState<Record<OrgRegistrationPackageId, boolean> | undefined>(
    p.playerBundle.exemptOrgRegistrationPackageIds
  );
  const orderedPackages = _.orderBy(p.allMatchingPackagesWithRankings, a => a.rank, "asc");
  const orderPackagesExcludingExempt = orderedPackages.filter(a => !exemptPackageIds?.[a.orgRegistrationPackage.id]);
  const currentlyAssignedPackage = orderPackagesExcludingExempt.length
    ? orderPackagesExcludingExempt[0].orgRegistrationPackage
    : undefined;

  return (
    <FullScreenModal
      children={
        <div>
          <div>
            {translate({
              defaultMessage:
                "By default, a player is automatically assigned the most expensive package when they belong to multiple teams in the same season. You can mark a player as exempt for a higher price package if you want them to only pay the cheaper package. You can also mark them exempt from all packages if you do not want to require the player to pay/register at all."
            })}
          </div>
          <div className="mt-4 font-bold">{translate({ defaultMessage: "Assigned Package" }) + ":"}</div>
          <div className="mt-1">
            {currentlyAssignedPackage
              ? `${currentlyAssignedPackage.name} (${formatMoneyCentsToDollarCentPrettyString(
                  currentlyAssignedPackage.amountCents
                )})`
              : translate.common.None}
          </div>
          <CoolerTable
            getRowCustomClassName={item => {
              if (currentlyAssignedPackage && currentlyAssignedPackage.id === item.orgRegistrationPackage.id) {
                return "bg-green-200";
              }
              return "";
            }}
            columnDefs={[
              {
                label: translate({ defaultMessage: "Exempt" }),
                getValue: item => {
                  return (
                    <CoolCheckboxInput
                      label=""
                      labelType="inside"
                      onChange={newVal => {
                        setExemptPackageIds({
                          ...exemptPackageIds,
                          [item.orgRegistrationPackage.id]: !exemptPackageIds?.[item.orgRegistrationPackage.id]
                        });
                      }}
                      value={!!exemptPackageIds?.[item.orgRegistrationPackage.id]}
                    />
                  );
                }
              },
              {
                label: translate.common.PackageShort,
                getValue(item) {
                  return item.orgRegistrationPackage.name;
                }
              },
              {
                label: translate.common.Amount,
                getValue(item) {
                  return formatMoneyCentsToDollarCentPrettyString(item.orgRegistrationPackage.amountCents);
                }
              }
            ]}
            items={orderedPackages}
            getItemKey={a => a.orgRegistrationPackage.id}
          />
        </div>
      }
      onRequestDismiss={p.onRequestDismiss}
      title={p.orgSeason.name}
      bottomButton={{
        title: translate.common.Save,
        onPress: async () => {
          try {
            await getBifrost().playerBundle__server__updateExemptRegistrationPackages.fetchServer({
              playerBundleId: p.playerBundle.id,
              newExemptDetails: exemptPackageIds ?? {}
            });
            await new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 2000);
            });
            await p.onSave();
            p.onRequestDismiss();
          } catch (e) {}
        }
      }}
    />
  );
}
